import * as React from "react"
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Container,
  Center,
  Heading,
  HStack,
  Stack,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
} from "@chakra-ui/react"
import { ColorModeSwitcher } from "./ColorModeSwitcher"
import { Logo } from "./Logo"
import BarsOfLife from "./BarsOfLife/BarsOfLife"
import { generateRandomStatusData } from "./utils/dummyDataGen"
import { Dashboard } from "./Dashboard"

export const App = () => (
  <ChakraProvider theme={theme}>
    <Container>

      <Box>
        <Grid minH="100vh" p={3}>
          <ColorModeSwitcher justifySelf="flex-end" />
          <VStack>
            <Heading size='xl' as='h1'>StatusNinja 🥷</Heading>
            <Tabs>
              <TabList>
                <Tab>Dash</Tab>
                <Tab>Scheduler Management</Tab>
                <Tab>Settings</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <Dashboard />
                </TabPanel>
                <TabPanel>
                  <p>two!</p>
                </TabPanel>
                <TabPanel>
                  <p>three!</p>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </VStack>


          {/* <VStack>
            <Heading size='xl' as='h1'>StatusNinja 🥷</Heading>
            <BarsOfLife data={generateRandomStatusData()} details={{ name: 'DataPulse' }} />
            <BarsOfLife data={generateRandomStatusData()} details={{ name: 'StatusSphere' }} size='sm' />
            <BarsOfLife data={generateRandomStatusData()} details={{ name: 'MetricMinds' }} size='sm' />
            <BarsOfLife data={generateRandomStatusData()} details={{ name: 'UptimeGenius' }} size='sm' />
            <BarsOfLife data={generateRandomStatusData()} details={{ name: 'HealthTrackr' }} size='sm' />
            <BarsOfLife data={generateRandomStatusData()} details={{ name: 'ServiceVista' }} size='sm' />

          </VStack> */}

        </Grid>
      </Box>
    </Container>
  </ChakraProvider>
)
