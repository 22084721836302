import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    FormControl,
    FormLabel,
    Input,
    Select,
    Textarea,
    useDisclosure,
} from '@chakra-ui/react';
import axios from 'axios';

const OrganizationFormModal = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [formData, setFormData] = useState({
        name: '',
        industry: '',
        email: '',
        phone: '',
        address: '',
        status: '',
        version: '',
        created_by: '',
        updated_by: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/api/organizations', formData);
            console.log('Organization created:', response.data);
            // Optionally reset the form or show success notification
            onClose(); // Close the modal after submission
        } catch (error) {
            console.error('Error creating organization:', error);
            // Handle error (e.g., show notification)
        }
    };

    return (
        <>
            <Button onClick={onOpen}>Add Organization</Button>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add New Organization</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <form onSubmit={handleSubmit}>
                            <FormControl mb={4} isRequired>
                                <FormLabel>Name</FormLabel>
                                <Input
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    placeholder="Organization Name"
                                />
                            </FormControl>
                            <FormControl mb={4}>
                                <FormLabel>Industry</FormLabel>
                                <Input
                                    name="industry"
                                    value={formData.industry}
                                    onChange={handleChange}
                                    placeholder="Industry"
                                />
                            </FormControl>
                            <FormControl mb={4} isRequired>
                                <FormLabel>Email</FormLabel>
                                <Input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    placeholder="Email"
                                />
                            </FormControl>
                            <FormControl mb={4}>
                                <FormLabel>Phone</FormLabel>
                                <Input
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    placeholder="Phone"
                                />
                            </FormControl>
                            <FormControl mb={4}>
                                <FormLabel>Address</FormLabel>
                                <Textarea
                                    name="address"
                                    value={formData.address}
                                    onChange={handleChange}
                                    placeholder="Address"
                                />
                            </FormControl>
                            <FormControl mb={4} isRequired>
                                <FormLabel>Status</FormLabel>
                                <Select
                                    name="status"
                                    value={formData.status}
                                    onChange={handleChange}
                                >
                                    <option value="">Select Status</option>
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
                                </Select>
                            </FormControl>
                            <FormControl mb={4}>
                                <FormLabel>Version</FormLabel>
                                <Input
                                    name="version"
                                    value={formData.version}
                                    onChange={handleChange}
                                    placeholder="Version"
                                />
                            </FormControl>
                            <FormControl mb={4}>
                                <FormLabel>Created By</FormLabel>
                                <Input
                                    name="created_by"
                                    value={formData.created_by}
                                    onChange={handleChange}
                                    placeholder="Created By"
                                />
                            </FormControl>
                            <FormControl mb={4}>
                                <FormLabel>Updated By</FormLabel>
                                <Input
                                    name="updated_by"
                                    value={formData.updated_by}
                                    onChange={handleChange}
                                    placeholder="Updated By"
                                />
                            </FormControl>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" onClick={handleSubmit}>
                            Submit
                        </Button>
                        <Button onClick={onClose} ml={3}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default OrganizationFormModal;
