import dayjs from 'dayjs';

interface StatusData {
  status: 'up' | 'down';
  ts: dayjs.Dayjs;
}

export const generateRandomStatusData = (
  maxItems: number = 24
): StatusData[] => {
  // Ensure maxItems is within the range 0 to 24
  const itemCount = Math.floor(Math.random() * (maxItems + 1));

  const statusData: StatusData[] = [];

  for (let i = 0; i < itemCount; i++) {
    // Randomly decide status
    const status: 'up' | 'down' = Math.random() > 0.5 ? 'up' : 'down';

    // Generate a timestamp from now to some minutes ago
    const ts = dayjs().subtract(i, 'minute');

    // Add the generated data to the array
    statusData.push({ status, ts });
  }
  console.log('statusData', statusData);
  return statusData;
};

// Example usage
// const contohData: StatusData[] = generateRandomStatusData();
// console.log(contohData);
