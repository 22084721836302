import { Box, Button, Container, Heading, HStack, Text, VStack } from "@chakra-ui/react";
import BarsOfLife from "./BarsOfLife/BarsOfLife";
import { generateRandomStatusData } from "./utils/dummyDataGen";
import { MdAddCircleOutline } from "react-icons/md";
import { ModalButton } from "./features/Modals/ModalButton";
import OrganizationFormModal from "./features/Modals/OrganizationFormModal";

export const Dashboard = () => (
    <Container>
        <VStack>
            <Button>
                <HStack>
                    <MdAddCircleOutline /> <Text>Monitor</Text>
                </HStack>
            </Button>
            <ModalButton />
            <OrganizationFormModal />
            <BarsOfLife data={generateRandomStatusData()} details={{ name: 'DataPulse' }} />
            <BarsOfLife data={generateRandomStatusData()} details={{ name: 'StatusSphere' }} size='sm' />
            <BarsOfLife data={generateRandomStatusData()} details={{ name: 'MetricMinds' }} size='sm' />
            <BarsOfLife data={generateRandomStatusData()} details={{ name: 'UptimeGenius' }} size='sm' />
            <BarsOfLife data={generateRandomStatusData()} details={{ name: 'HealthTrackr' }} size='sm' />
            <BarsOfLife data={generateRandomStatusData()} details={{ name: 'ServiceVista' }} size='sm' />
        </VStack>

    </Container>
)