import { Box, Flex, HStack, Heading, Icon, Text, Tooltip, useColorModeValue } from "@chakra-ui/react";
import dayjs, { Dayjs } from "dayjs";
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { IoIosAperture, IoMdArrowDropupCircle } from "react-icons/io";
import { MdArrowDropDownCircle } from "react-icons/md";
import LifeDot from "./LifeDot";


dayjs.extend(localizedFormat)

type StatusData = {
    status: string, ts: Date | Dayjs,
}
type ItemDetails = {
    name: string,
}

const contohData: StatusData[] = [
    { status: 'up', ts: dayjs().subtract(1, 'minute'), },
    { status: 'down', ts: dayjs().subtract(2, 'minute'), },
    { status: 'up', ts: dayjs().subtract(3, 'minute'), },
    { status: 'up', ts: dayjs().subtract(4, 'minute'), },
];

const MINIMUM_COUNT = 24;

const DotSection = ({ data }: { data: StatusData[] }) => {
    return (<Flex>

        {data.length < MINIMUM_COUNT ? (
            Array(MINIMUM_COUNT - data.length).fill({ status: 'no_data' }).map((el, index) => {
                return <LifeDot ts={null} key={index} status={el.status} />
            })) : null
        }
        {data.map((el, index) => (
            <LifeDot ts={el.ts} key={index} status={el.status} />
        ))}
    </Flex>)
}

const MediumBarsOfLife = ({ uptimePercentage, bgColor, textColor, data }: { uptimePercentage: number, bgColor: string, textColor: string, data: StatusData[] }) => {
    return (
        <Box bg={bgColor} p={4} borderRadius="md" width="fit-content">
            <Text color={textColor} mb={2}>Last 24 hours</Text>
            <Text color={textColor} fontSize="2xl" mb={2}>{uptimePercentage.toFixed(2)}%</Text>
            <DotSection data={data} />
            <Text color={textColor} mt={2}>0 incidents, 0m down</Text>
        </Box>
    );
}

const IconUpOrDown = ({ type }: { type: 'up' | 'down' }) => {
    const tooltipLabel = type === 'up' ? 'Currently up!' : 'Uh oh! its DOWN!';
    return (<Tooltip label={tooltipLabel}>
        <Box>
            {type === 'up' ? <Icon color={'green.400'} as={IoMdArrowDropupCircle} /> : <Icon color={'red.400'} as={MdArrowDropDownCircle} />}
        </Box>
    </Tooltip>);
}

const SmallBarsOfLife = ({ details, uptimePercentage, bgColor, textColor, data }: { details: ItemDetails, uptimePercentage: number, bgColor: string, textColor: string, data: StatusData[] }) => {
    return (
        <Box bg={bgColor} p={4} borderRadius="md" width="fit-content">
            <Flex direction="column">
                {/* Flex container for aligning items */}
                <Flex mb={2} justify="space-between" align="center">
                    {/* Left side: Icon and Heading */}
                    <HStack >
                        <Icon color="gray.500" as={IoIosAperture} />
                        <Heading size='sm' color={textColor}>{details.name}</Heading>
                    </HStack>
                    {/* Right side: Percentage */}
                    <HStack>
                        <Text color={textColor} fontSize="sm">{uptimePercentage.toFixed(2)}%</Text>
                        <IconUpOrDown type='up' />
                    </HStack>
                </Flex>
                {/* Other component */}
                <DotSection data={data} />
            </Flex>
        </Box>
    );
}

const BarsOfLife = ({ details, data = [], size = 'md' }: { details: ItemDetails, data?: StatusData[], size?: 'md' | 'sm' }) => {
    // const statusBar = data.map(el => el.status); // Example: All hours are up

    const uptimePercentage = 92.452
    // Use useColorModeValue to determine the background color based on the color mode
    const bgColor = useColorModeValue("gray.50", "gray.900");
    const textColor = useColorModeValue("black", "white");

    return (size === 'md' ? <MediumBarsOfLife uptimePercentage={uptimePercentage} bgColor={bgColor} textColor={textColor} data={data} /> :
        <SmallBarsOfLife details={details} uptimePercentage={uptimePercentage} bgColor={bgColor} textColor={textColor} data={data} />
    );
}

export default BarsOfLife;
